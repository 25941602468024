/* eslint-disable camelcase */
/* eslint-disable max-lines */
/* eslint-disable complexity */
import React from 'react'
import moment from 'moment'
import 'moment/locale/es'
import styles from './ChooseDates.module.css'
import messages from './ChooseDates.messages'
import apiAcuity from '../../Api/Acuity'
import Utils from '../../Utils/utils'
import LoaderSection from '../LoaderSection/LoaderSection'
import implant from '../../Assets/images/svg/implant.svg'
import discount from '../../Assets/images/svg/discount.svg'
import buenfin from '../../Assets/images/svg/buen_fin.svg'
import arrow from '../../Assets/images/svg/arrowBack.svg'
import left from '../../Assets/images/svg/left.svg'
import right from '../../Assets/images/svg/right.svg'
import leftGray from '../../Assets/images/svg/leftGray.svg'
import rightGray from '../../Assets/images/svg/rightGray.svg'
import mask from '../../Assets/images/svg/mask.svg'
import queryString from 'query-string';
import { FREE_APPOINMENT_VARIANT_MEDELLIN, FREE_APPOINMENT_VARIANT_BUCARAMANGA, PROMO_APPOINMENT_VARIANT_MEDELLIN, PROMO_APPOINMENT_VARIANT_BUCARAMANGA } from '../../Utils/constants'
import { setTrackingPatient } from '../../Utils/tracking'
import FullModal from '../FullModal/FullModal'
import { apiBlackBox } from '../../Api/BlackBox';
import calendar from '../../Assets/images/png/calendar.png'
import checked from '../../Assets/images/svg/checked.svg'

/**
 * ChooseDates Component
 * @return {void}
 */
class ChooseDates extends React.Component {
  /**
   * Constructor
   * @param {*} props .
   */
  constructor(props) {
    super(props)
    let search = {}
    if(props && props.history.location && props.history.location.search){
      search = queryString.parse(props.history.location.search)
      if(search.state){
        delete search['state']
      }
    }
    props.history.push("?" + new URLSearchParams(search).toString())


    this.state = {
      dates: [],
      labelDates: {},
      loader: true,
      centerDate: 1,
      times: '',
      enablePriorityBooking: false,
      showPriorityBooking: false,
      priorityBookingCta:false,
      services : props?.services || false,
      modal:{
        showModal: false,
        content: null
      },
    }
  }

  /**
   * Component Did Mount
   * @return {void}
   */
  componentDidMount = () => {
    this.calculateDates()
    const { patient } = this.props
    if (patient.Country_Ops === 'México') {
      this.setState({enablePriorityBooking:true});
    } 
    let disablePriorityBooking = patient?.Priority_Booking == "To Be Contacted" || patient?.Priority_Booking == "Contacted"
    this.setState({priorityBookingCta:disablePriorityBooking});
    let data = {
      distinct_id: patient.CustomerId,
      customer_id: patient.CustomerId,
      country_ops: patient.Country_Ops,
      name: patient.Patient_Name,
      email: patient.Email,
      phone: patient.Phone,
      platform: 'appointment_platform_v2',
    };
  }

  calculateDates = async () => {
    const { branch } = this.props
    const id = branch && branch.Appointment_Type_Id
    const calendarId = branch?.Calendar_Id || false
    const appointment = await apiAcuity.appointmentTypes()
    let dates = []
    let nextDates = []
    let nextDates2 = []
    let nextDates3 = []

    const calendar = Array.isArray(appointment) && appointment.find(
      (ap) => ap.id && ap.id.toString() === id
    )

    if (calendar) {
      const { month, nextMonth, nextMonth2, nextMonth3 } = this.dateToAcuity()
      dates = await apiAcuity.dates(calendar.id, month, (calendarId || calendar.calendarIDs[0]))
      dates = dates.map((item) => {
        item.calendarId = calendarId
        item.appointmentTypeId = branch?.Appointment_Type_Id
        return item
      })

      let dates2 = []
      if(!this.state.services && branch?.mergeCalendars?.length > 0){
        dates2 = await apiAcuity.dates(branch?.mergeCalendars?.[0]?.appointmentTypeId, month, branch?.mergeCalendars?.[0]?.calendarId)
        dates2 = dates2.map((item) => {
          item.calendarId = branch?.mergeCalendars?.[0]?.calendarId
          item.appointmentTypeId = branch?.mergeCalendars?.[0]?.appointmentTypeId
          return item
        })
      }
      dates = dates.concat(dates2)
      if (this.state?.services) {
        nextDates = await apiAcuity.dates(calendar.id, nextMonth, (calendarId || calendar.calendarIDs[0]))
        nextDates2 = await apiAcuity.dates(calendar.id, nextMonth2, (calendarId || calendar.calendarIDs[0]))
        nextDates3 = await apiAcuity.dates(calendar.id, nextMonth3, (calendarId || calendar.calendarIDs[0]))
        if(nextDates2.length > 0){
          nextDates = nextDates.concat(nextDates2)
        }
        if(nextDates3.length > 0){
          nextDates = nextDates.concat(nextDates3)
        }
      }else{
          nextDates = await apiAcuity.dates(calendar.id, nextMonth, (calendarId || calendar.calendarIDs[0]))
          nextDates = nextDates.map((item) => {
            item.calendarId = calendarId
            item.appointmentTypeId = branch?.Appointment_Type_Id
            return item
          })
          if(!this.state.services && branch?.mergeCalendars?.length > 0){
            nextDates2 = await apiAcuity.dates(branch?.mergeCalendars?.[0]?.appointmentTypeId, nextMonth, branch?.mergeCalendars?.[0]?.calendarId)
            nextDates2 = nextDates2.map((item) => {
              item.calendarId = branch?.mergeCalendars?.[0]?.calendarId
              item.appointmentTypeId = branch?.mergeCalendars?.[0]?.appointmentTypeId
              return item
            })
            if(nextDates2.length > 0){
              nextDates = nextDates.concat(nextDates2)
            }
          }
      }

      dates = dates.sort(function compare(a, b) {
        var dateA = new Date(a.date);
        var dateB = new Date(b.date);
        return dateA - dateB;
      });

      nextDates = nextDates.sort(function compare(a, b) {
        var dateA = new Date(a.date);
        var dateB = new Date(b.date);
        return dateA - dateB;
      });


      let { labelDates, newDates } = this.mapDates(dates, nextDates)
      
      let times = Array.isArray(newDates)
        && await Promise.all(newDates.map(async date => {return {date:date, times: await this.getOneTime(date, calendar, calendarId)}}))

      const datesAux = {}
      Array.isArray(times) && times.map(item => {
        item.times && item.times.map(time => {
          if(datesAux[item?.date?.date] && datesAux[item?.date?.date]?.length > 0){
            datesAux[item?.date?.date].push({time:time.time, appointmentTypeId: item.date.appointmentTypeId, calendarId: item.date.calendarId})
          }else{
            datesAux[item?.date?.date] = []
            datesAux[item?.date?.date].push({time:time.time, appointmentTypeId: item.date.appointmentTypeId, calendarId: item.date.calendarId})
          }
        })
        return datesAux
      })

      times = Object.keys(datesAux).map(key => {
        datesAux[key].sort((a,b) => {
          return moment(a.time).diff(moment(b.time))
        }
        )
        let unique = []
        datesAux[key].map(item => {
          if(unique.length === 0){
            unique.push(item)
          }
          if(unique[unique.length - 1].time !== item.time){
            unique.push(item)
          }
        }
        )
          
        return unique.map(item => {
          return item
        })
      })

      const exists = []
      newDates = newDates.filter((item)=>{
        if(!exists.includes(item.date)){
          exists.push(item.date)
          return item
        }
      })

      this.setState({
        dates: newDates, labelDates, times, loader: false
      })
    } else {
      this.setState({ loader: false, showPriorityBooking: true })
    }
  }

  /**
   * Get One Time
   * @param {Array} date .
   * @param {Object} calendar .
   * @return {void}
   */
  getOneTime = async (date, calendar, calendarId=false) => {
    const times = await apiAcuity.times((date?.appointmentTypeId || calendar.id), date?.date || date, (date?.calendarId || calendarId || calendar.calendarIDs[0]))
    return times
  }

  /**
   * Map Dates
   * @param {Array} dates .
   * @param {Array} nextDate .
   * @return {void}
   */
  mapDates = (dates, nextDate) => {
    if(dates.length < 1 && nextDate.length < 1){
      return { newDates:false, labelDates:[] }
    }

    const tempDate = dates.concat(nextDate)
    while (tempDate.length > 101) {
      tempDate.pop()
    }

    let one = moment(tempDate[tempDate.length - 1].date).add(1, 'days').format('dddd DD/MM');
    let two = moment(tempDate[tempDate.length - 1].date).add(2, 'days').format('dddd DD/MM');
    let three = moment(tempDate[tempDate.length - 1].date).add(3, 'days').format('dddd DD/MM');

    this.setState({ extraDays: {
      one,
      two,
      three
    }
    })
    const labelDates = []
    Array.isArray(tempDate) && tempDate.map(item => {
      const temp = moment(item.date).format('dddd DD/MM')
      if(!labelDates?.includes(temp)){
        labelDates.push(temp)
      }
    })

    const newDates = Array.isArray(tempDate) && tempDate.map(item => item)
    return { newDates, labelDates }
  }

  dateToAcuity = () => {
    const month = moment().format('YYYY-MM')
    const nextMonth = moment().add(1, 'M').format('YYYY-MM')
    const nextMonth2 = moment().add(2, 'M').format('YYYY-MM')
    const nextMonth3 = moment().add(3, 'M').format('YYYY-MM')
    return { month, nextMonth, nextMonth2, nextMonth3 }
  }

  itemChoose = () => {
    const { branch } = this.props
    const {
      Center_Name, Street, Number, Neighborhood
    } = branch
    const address = `${Street} ${Number}, ${Neighborhood}`

    return (
      <div
        className={styles.CenterContainer}
        key={branch.Appointment_Type_Id}
      >
        {branch.Center_Icon ? (
          <img
            className={styles.Icon}
            alt="icon"
            src={branch.Center_Icon}
          />
        ) : (
          <img
            className={styles.Icon}
            alt="icon"
            src={implant}
          />
        )}
        {this.props.promoAppointment === FREE_APPOINMENT_VARIANT_MEDELLIN || this.props.promoAppointment === FREE_APPOINMENT_VARIANT_BUCARAMANGA || this.props.promoAppointment === PROMO_APPOINMENT_VARIANT_MEDELLIN || this.props.promoAppointment === PROMO_APPOINMENT_VARIANT_BUCARAMANGA
          ?
          <>
            <div className={`${styles.AddressContainer} ${styles.promoAppointmentContainer}`}>
              <div className={styles.promoAppointmentTitleContainer}>
                <p className={styles.Center}>{Center_Name}</p>
                <div className={styles.promoAppointmentTextContainer}>
                  {(this.props.promoAppointment === FREE_APPOINMENT_VARIANT_MEDELLIN || this.props.promoAppointment === FREE_APPOINMENT_VARIANT_BUCARAMANGA) && this.props.counterPromo
                    ?
                    <>
                      <span/>
                      <p className={styles.promoAppointmentText}>CITA GRATIS</p>
                    </> :
                    <>
                      <span>   </span>
                      <p>PROMO CITA</p>
                    </>}
                </div>
              </div>
              <p className={styles.Address}>{address}</p>
            </div>
          </>
          : (
            <div className={styles.AddressContainer}>
              <p className={styles.Center}>{Center_Name}{Center_Name.includes('Polanco111')?<img className={styles.discount} alt="icon"src={discount} /> : null}</p>
              <p className={styles.Address}>{address}</p>
            </div>
          )}
      </div>
    )
  }

  backComponent = () => {
    const { nextStep } = this.props
    return (
      <div
        role="button"
        tabIndex={0}
        className={styles.Back}
        onClick={() => nextStep(true)}
      >
        <img
          alt="logo"
          src={arrow}
        />
        <p>{messages.back}</p>
      </div>
    )
  }

  successSubmit = () => {
    this.setState({modal:{
      showModal:false,
    }});
  }

  contactSubmit = async () => {
    const { patient , branch } = this.props
    let data = {
      distinct_id: patient.CustomerId,
      customer_id: patient.CustomerId,
      country_ops: patient.Country_Ops,
      name: patient.Patient_Name,
      email: patient.Email,
      phone: patient.Phone,
      center_name: branch.Center_Name,
      platform: 'appointment_platform_v2',
    };
    setTrackingPatient(patient,{name:'Old Appointment Platform - Priority Booking Confirmed', data})
    this.setState({modal:{showModal:true}})
    const customerId = patient.CustomerId
    const response = await apiBlackBox.Patient(
      customerId
    );

    if(response.success){
      this.setState({priorityBookingCta:true})
      this.setState({modal:{
        showModal:true,
        content:this.BookingSuccessContent
      }})
    }else{
      this.setState({modal:{
        showModal:true,
        content:this.errorBookingContent
      }})
      
    }
  }

  priorityBookingContent = (
    <div className={styles.PopupCard}>
      <img src={calendar}/>
      <div className={styles.PopupTitle}>{messages.priorityBookingTitle}</div>
      <div className={styles.PopupDesc}>{messages.priorityBookingDescription}</div>
      <button
        type="button"
        className={styles.PopupBtn}
        onClick={() => this.contactSubmit()}
      >
        {messages.priorityBookingCta}
      </button>
      <span onClick={() => this.setState({modal:{showModal:false}}) } className={styles.PopupLink} >{messages.priorityBookingCta2}</span>
    </div>
  )

  BookingSuccessContent = (
    <div className={styles.PopupCard}>
      <img src={checked}/>
      <div className={styles.PopupTitle}>{messages.bookingSuccessTitle}</div>
      <div className={styles.PopupDesc}>{messages.bookingSuccessDescription}</div>
        <button
         type="button"
         className={styles.PopupBtn}
         onClick={() => this.successSubmit()}
        >
         {messages.bookingSuccessCta}
        </button>
    </div>
  )

  errorBookingContent = (
    <div className={styles.PopupCard}>
      <div className={styles.PopupTitle}>{messages.bookingErrorTitle}</div>
      <div className={styles.PopupDesc}>{messages.bookingErrorDescription}</div>
      <button
        type="button"
        className={styles.PopupBtn}
        onClick={() => this.contactSubmit()}
      >
        {messages.bookingErrorCta}
      </button>
      <span onClick={() => this.setState({modal:{showModal:false}}) } className={styles.PopupLink} >{messages.bookingErrorCta2}</span>
    </div>
  )

  contactMe = () => {
    const { patient, branch } = this.props
    let data = {
      distinct_id: patient.CustomerId,
      customer_id: patient.CustomerId,
      country_ops: patient.Country_Ops,
      name: patient.Patient_Name,
      email: patient.Email,
      phone: patient.Phone,
      center_name: branch.Center_Name,
      platform: 'appointment_platform_v2',
    };
    setTrackingPatient(patient,{name:'Old Appointment Platform - Priority Booking Required', data})
    
    this.setState({modal:{
      showModal:true,
      content:this.priorityBookingContent
    }})
  }

  containerMobile = () => {
    const { times, labelDates, centerDate } = this.state
    const { patient, branch } = this.props
    const country = patient.Country_Ops
    const now = moment().format('dddd DD/MM')
    const appointmentTypeId = branch && branch.Appointment_Type_Id ? branch.Appointment_Type_Id : false

    return (
      <>
        {this.state.showPriorityBooking ?
          (
            <div className={styles.ContainerMobile} >
              <div className={styles.Item}>
                <p className={styles.Label}></p>
                <div className={styles.bookingPriorityContainer}>
                  <div className={styles.bookingTitle}>{messages.bookingTitle}</div>
                  <p className={styles.bookingDescription}>
                    {messages.bookingDescription}
                  </p>
                  <a
                    disabled={this.state.priorityBookingCta}
                    tabIndex={0}
                    className={this.state.priorityBookingCta ? styles.bookingCtaDisabled : styles.bookingCta}
                    // onClick={() => this.contactMe()}
                    href="https://wa.me/525571000303?text=Hola%2C%20necesito%20ayuda%20para%20encontrar%20horario%20para%20mi%20cita%20Moons%20"
                    target="_blank"
                  >
                    {messages.bookingCta}
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <div
              className={styles.ContainerMobile}
            >
              <div className={styles.Item}>
                {Array.isArray(times) && Array.isArray(times[centerDate - 1])
                  && times[centerDate - 1].length > 0 ?
                  <>
                    <p className={styles.Label}>{labelDates[centerDate - 1] || now}</p>
                    {
                      times[centerDate - 1].map(item => {
                        const time = Utils.getDateByCountryTimeZone(item.time, country, appointmentTypeId)
                        let currentItem = moment(item.time)
                        let day = currentItem.isSame(moment(), 'day') || currentItem.isSame(moment().add(1, 'day'), 'day') || currentItem.isSame(moment().add(2, 'day'), 'day');
                        let discountImg = day ? <img className={styles.discount} alt="icon" src={discount} /> : null;

                        try {
                          var currentMonth = parseInt(currentItem.format('M'));
                          var currentDay = parseInt(currentItem.format('D'));
                          if (country === 'México' && currentMonth == 11 && currentDay >= 13 && currentDay <= 20) {
                            day = true;
                            discountImg = day ? <img className={styles.discount} alt="icon" src={buenfin} /> : null;
                          } else if (currentMonth == 11 && currentDay <= 27) {
                            day = true;
                            discountImg = <img className={styles.discount} alt="icon" src={discount} />;
                          }
                        } catch (err) {
                          console.log(err)
                        }
                        return (
                          <div
                            role="button"
                            tabIndex={0}
                            onClick={() => this.trackEvent(item)}
                            key={item.time}
                            className={styles.Time}
                          >
                            {time}
                            {discountImg}
                          </div>
                        )
                      })
                    }
                  </>
                  :
                  <></>
                }
              </div>
            </div>
          )
        }
      </>
    )
  }

  trackEvent(time){
    const { selectTime, patient, branch } = this.props
    selectTime(time)
    let data = {
      distinct_id: patient.CustomerId,
      customer_id: patient.CustomerId,
      country_ops: patient.Country_Ops,
      center_name: branch.Center_Name,
      platform:'appointment_platform_v2',
      public_key: patient.PublicKey,
      appointment_date:time
    }
    setTrackingPatient(patient,{name:'Old Appointment Platform - Day And Time Selected', data})
  }

  containerDesktop = () => {
    const { dates, times, centerDate, labelDates } = this.state
    const {patient, branch } = this.props
    const country = patient.Country_Ops
    const one = moment().format('dddd DD/MM')
    let dateTwo = moment().add(1, 'days')
    let dateThree = moment().add(2, 'days')

    let currentDate = null
    if (Array.isArray(dates) && dates[centerDate - 1]) {
      currentDate = dates[centerDate - 1]
      dateTwo = moment(currentDate).add(1, 'days')
      dateThree = moment(currentDate).add(2, 'days')
    }
    if (Array.isArray(dates) && dates[centerDate]) {
      currentDate = dates[centerDate]
      dateThree = moment(currentDate).add(1, 'days')
    }
    const two = dateTwo.format('dddd DD/MM')
    const three = dateThree.format('dddd DD/MM')

    const appointmentTypeId = branch && branch.Appointment_Type_Id ? branch.Appointment_Type_Id : false

    return (
      <>
        {this.state.showPriorityBooking ?
          (<div>
            <div className={styles.priorityDatesContainer}>
              <div className={styles.Item}>                 
              </div>
              <div className={styles.Item}> 
              </div>
              <div className={styles.Item}> 
              </div>
            </div>
            <div className={styles.bookingPriorityContainer}>
              <div className={styles.bookingTitle}>{messages.bookingTitle}</div>
              <p className={styles.bookingDescription}>
                {messages.bookingDescription}
              </p>
              <a
                disabled={this.state.priorityBookingCta}
                tabIndex={0}
                className={this.state.priorityBookingCta ? styles.bookingCtaDisabled : styles.bookingCta}
                //  onClick={() => this.contactMe()}
                href="https://wa.me/525571000303?text=Hola%2C%20necesito%20ayuda%20para%20encontrar%20horario%20para%20mi%20cita%20Moons%20"
                target="_blank"
              >
                {messages.bookingCta}
              </a>
            </div>
          </div>
          ) : (<div className={styles.ContainerDesktop}>
            <div className={styles.Item}>
              {Array.isArray(times) && Array.isArray(times[centerDate - 1]) && times[centerDate - 1].length > 0 ?
                <>
                  <p className={styles.Label}>{labelDates[centerDate - 1] || one}</p>
                  {times[centerDate - 1].map(item => {
                    const time = Utils.getDateByCountryTimeZone(item.time, country, appointmentTypeId)
                    let currentItem = moment(item.time)
                    let day = currentItem.isSame(moment(), 'day') || currentItem.isSame(moment().add(1, 'day'), 'day') || currentItem.isSame(moment().add(2, 'day'), 'day');
                    let discountImg = day ? <img className={styles.discount} alt="icon" src={discount} /> : null;

                    try {
                      var currentMonth = parseInt(currentItem.format('M'));
                      var currentDay = parseInt(currentItem.format('D'));
                      if (country === 'México' && currentMonth == 11 && currentDay >= 13 && currentDay <= 20) {
                        day = true;
                        discountImg = day ? <img className={styles.discount} alt="icon" src={buenfin} /> : null;
                      } else if (currentMonth == 11 && currentDay <= 27) {
                        day = true;
                        discountImg = <img className={styles.discount} alt="icon" src={discount} />;
                      }
                    } catch (err) {
                      console.log(err)
                    }
                    return (
                      <div
                        role="button"
                        tabIndex={0}
                        onClick={() => this.trackEvent(item)}
                        key={item.time}
                        className={styles.Time}
                      >
                        {time}
                        {discountImg}
                      </div>
                    )
                  })}
                </>
                :
                <></>
              }
            </div>
            <div className={styles.Item}>
              {Array.isArray(times) && Array.isArray(times[centerDate]) && times[centerDate].length > 0 ? <>
                <p className={styles.Label}>{labelDates[centerDate] || two}</p>
                {
                  times[centerDate].map(item => {
                    const time = Utils.getDateByCountryTimeZone(item.time, country, appointmentTypeId)
                    let currentItem = moment(item.time)
                    let day = currentItem.isSame(moment(), 'day') || currentItem.isSame(moment().add(1, 'day'), 'day') || currentItem.isSame(moment().add(2, 'day'), 'day');
                    let discountImg = day ? <img className={styles.discount} alt="icon" src={discount} /> : null;

                    try {
                      var currentMonth = parseInt(currentItem.format('M'));
                      var currentDay = parseInt(currentItem.format('D'));
                      if (country === 'México' && currentMonth == 11 && currentDay >= 13 && currentDay <= 20) {
                        day = true;
                        discountImg = day ? <img className={styles.discount} alt="icon" src={buenfin} /> : null;
                      } else if (currentMonth == 11 && currentDay <= 27) {
                        day = true;
                        discountImg = <img className={styles.discount} alt="icon" src={discount} />;
                      }
                    } catch (err) {
                      console.log(err)
                    }
                    return (
                      <div
                        role="button"
                        tabIndex={0}
                        onClick={() => this.trackEvent(item)}
                        key={item.time}
                        className={styles.Time}
                      >
                        {time}
                        {discountImg}
                      </div>
                    )
                  })
                }
              </>
                :
                <></>
              }
            </div>
            <div className={styles.Item}>
              {Array.isArray(times) && Array.isArray(times[centerDate + 1]) && times[centerDate + 1].length > 0 ? <>
                <p className={styles.Label}>{labelDates[centerDate + 1] || three}</p>
                {times[centerDate + 1].map(item => {
                  const time = Utils.getDateByCountryTimeZone(item.time, country, appointmentTypeId)
                  let currentItem = moment(item.time)
                  let day = currentItem.isSame(moment(), 'day') || currentItem.isSame(moment().add(1, 'day'), 'day') || currentItem.isSame(moment().add(2, 'day'), 'day');
                  let discountImg = day ? <img className={styles.discount} alt="icon" src={discount} /> : null;

                  try {
                    var currentMonth = parseInt(currentItem.format('M'));
                    var currentDay = parseInt(currentItem.format('D'));
                    if (country === 'México' && currentMonth == 11 && currentDay >= 13 && currentDay <= 20) {
                      day = true;
                      discountImg = day ? <img className={styles.discount} alt="icon" src={buenfin} /> : null;
                    } else if (currentMonth == 11 && currentDay <= 27) {
                      day = true;
                      discountImg = <img className={styles.discount} alt="icon" src={discount} />;
                    }
                  } catch (err) {
                    console.log(err)
                  }
                  return (
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => this.trackEvent(item)}
                      key={item.time}
                      className={styles.Time}
                    >
                      {time}
                      {discountImg}
                    </div>
                  )
                })
                }
              </> :
                <></>    
  }        
          </div>
        </div>)
      }
      </>
    )
  }

  /**
   * Change Date
   * @param {String} dir .
   * @return {void}
   */
  changeDate = (dir) => {
    const { dates, centerDate } = this.state
    const isDesktop = window.innerWidth > 700

    if (isDesktop) {
      if (dir === 'left' && dates[centerDate - 2]) {
        if (this.state.showPriorityBooking) {
          this.setState({ showPriorityBooking: false })
        }else{
          this.setState({ centerDate: centerDate - 1 })
        }
      } else if (dir === 'right' && dates[centerDate + 2]) {
        this.setState({ centerDate: centerDate + 1 })
      }else{
        if (dir === 'right' && !this.state.showPriorityBooking && this.state.enablePriorityBooking) {
          this.setState({ showPriorityBooking: true })
        }else if (dir === 'left' && this.state.showPriorityBooking){
          this.setState({ centerDate: centerDate - 1 })
          this.setState({ showPriorityBooking: !(dates?.length > 0) })
        }
      }
      return
    }

    if (dir === 'left' && dates[centerDate - 2]) {
      if (this.state.showPriorityBooking) {
        this.setState({ showPriorityBooking: false })
      }else{
        this.setState({ centerDate: centerDate - 1 })
      }
    } else if (dir === 'right' && dates[centerDate]) {
      this.setState({ centerDate: centerDate + 1 })
    }else{
      if (dir === 'right' && !this.state.showPriorityBooking && this.state.enablePriorityBooking) {
        this.setState({ showPriorityBooking: true })
      }
    }
  }

  /**
   * Render
   * @returns {void}
   */
  render() {
    const { loader, dates, centerDate } = this.state
    const { name } = this.props
    const welcome = messages.welcome.replace('{Name}', name)
    const isDesktop = window.innerWidth > 700
    let showLeft = !!dates[centerDate - 2]
    let showRight = !!dates[centerDate]

    if (isDesktop) {
      if (!dates[centerDate - 2]) {
        showLeft = false
      }
      if (!dates[centerDate + 2]) {
        if (!this.state.showPriorityBooking && this.state.enablePriorityBooking) {
          showRight = true
        }else{
          showRight = false
        }
      }
    }else{
      if (!dates[centerDate + 2]) {
        if (!this.state.showPriorityBooking && this.state.enablePriorityBooking) {
          showRight = true
        }
      }
    }

    if (loader) {
      return (
        <div className={styles.ContainerLoader}>
          <LoaderSection />
        </div>
      )
    }

    const calendarId = this.props?.branch?.Calendar_Id
    
    return (
      <div className={styles.ContainerDates}>
        { this.props.promoAppointment === FREE_APPOINMENT_VARIANT_MEDELLIN || this.props.promoAppointment === FREE_APPOINMENT_VARIANT_BUCARAMANGA || this.props.promoAppointment === PROMO_APPOINMENT_VARIANT_MEDELLIN || this.props.promoAppointment === PROMO_APPOINMENT_VARIANT_BUCARAMANGA? null: this.backComponent()}
        {isDesktop && <p className={styles.TitleName}>{welcome}</p>}
        {this.itemChoose()}
        <p className={styles.Select}>{this.state.showPriorityBooking ? '' : messages.select}</p>
        {/* <div className={styles.Timing}>{messages.timing}</div> */}
        <div className={styles.DatesContainer}>
          <div className={styles.AllDates}>
            <div
              role="button"
              tabIndex={0}
              className={styles.ImageArrow}
              onClick={() => this.changeDate('left')}
            >
              {showLeft ? (
                <img
                  className={styles.Left}
                  alt="logo"
                  src={left}
                />
              ) : (
                <img
                  className={styles.Left}
                  alt="logo"
                  src={leftGray}
                />
              )}
            </div>
            {isDesktop ? this.containerDesktop() : this.containerMobile()}
            <div
              role="button"
              tabIndex={0}
              onClick={() => this.changeDate('right')}
              className={styles.ImageArrow}
            >
              {showRight ? (
                <img
                  className={styles.Right}
                  alt="logo"
                  src={right}
                />
              ) : (
                <img
                  className={styles.Right}
                  alt="logo"
                  src={rightGray}
                />
              )}
            </div>
          </div>
        </div>
        <FullModal
          open={this.state.modal.showModal}
          content={this.state.modal.content}
        />
      </div>
    )
  }
}

export default ChooseDates
